<template>
  <v-container>
    <div class="revenuesContainer">
      <v-list-item class="item" v-for="payment in payments" :key="payment.id">
        <v-list-item-content>
          <v-list-item-title
            v-text="formatFromToDate(payment.from, payment.to)"
          />
          <v-list-item-subtitle>
            <h2>{{ `${payment.amount} pln` }}</h2>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </div>
  </v-container>
</template>

<script>
export default {
  props: {
    payments: null,
  },
  methods: {
    formatFromToDate(from, to) {
      const formattedFrom = this.$moment(from).format("DD.MM.YYYY");
      const formattedTo = this.$moment(to).format("DD.MM.YYYY");

      return `${formattedFrom} - ${formattedTo}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.revenuesContainer {
  height: calc(100vh - 200px);
  overflow-y: auto;
}

.item {
  border-bottom: 1px solid #30a683;
}
</style>