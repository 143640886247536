<template>
  <RegisterUser class="page" />
</template>

<script>
import RegisterUser from "@/components/admin/RegisterUser";
export default {
  components: {
    RegisterUser,
  },
};
</script>