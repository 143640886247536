// mutations
export const setLoginState = 'setLoginState'
export const setLogoutState = 'setLogoutState'
export const setLoginError = 'setLoginError'
export const setIsLoginLoading = 'setIsLoginLoading'
export const setSignupError = 'setSignupError'
export const setIsSignupLoading = 'setIsSignupLoading'
export const setSignupState = 'setSignupState'

export const setChangePasswordState = 'setChangePasswordState'
export const setIsChangePasswordLoading = 'setIsChangePasswordLoading'

export const setIsResetPasswordLoading = 'setIsResetPasswordLoading'
export const setResettedPassword = 'setResettedPassword'
export const setIsResetPasswordError = 'setIsResetPasswordError'

// actions
export const login = 'login'
export const logout = 'logout'
export const signup = 'signup'
export const changePassword = 'changePassword'
export const resetPassword = 'resetPassword'