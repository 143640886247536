<template>
  <v-form ref="form" @submit.prevent="saveUser">
    <v-container>
      <v-alert type="error" dismissible v-show="isSaveError">
        {{ $t("admin.userInfo.editUserInfoError") }}
      </v-alert>
      <v-row justify="space-around">
        <v-col sm="6" cols="12">
          <v-text-field
            v-model="userInfo.id"
            :label="$t('admin.userInfo.id')"
            readonly
          />
          <v-text-field
            v-model="userInfo.username"
            :label="$t('admin.userInfo.username')"
            readonly
          />
          <v-text-field
            v-model="userInfo.email"
            :rules="emailRules"
            :label="$t('admin.userInfo.email')"
            required
            type="email"
          />
          <v-text-field
            v-model="userInfo.phone"
            :rules="phoneRules"
            type="phone"
            v-mask="'+48-###-###-###'"
            :label="$t('admin.userInfo.phone')"
            required
          />
          <v-text-field
            v-model="userInfo.name"
            :rules="textRules"
            :label="$t('admin.userInfo.name')"
            required
            type="text"
          />
          <v-text-field
            v-model="userInfo.surname"
            :rules="textRules"
            :label="$t('admin.userInfo.surname')"
            required
            type="text"
          />
          <v-select
            :items="accountTypeOptions"
            :label="$t('admin.userInfo.accountType')"
            v-model="userInfo.accountType"
          />
        </v-col>
        <v-col sm="6" cols="12">
          <v-text-field
            readonly
            :value="
              userData.referrerId == null
                ? userInfo.referrerId
                : userData.referrerId
            "
            :label="$t('admin.userInfo.referrerId')"
          />
          <v-autocomplete
            :items="users"
            :value="
              userInfo.referrer == null
                ? $t('admin.userInfo.noReferrer')
                : userInfo.referrer.username
            "
            @keyup="findUserByUsername($event.target.value)"
            @focus="firstLoad"
            @change="selectNewReferrer"
            :label="$t('admin.userInfo.referrerUsername')"
          />
        </v-col>
      </v-row>
      <v-btn type="submit" color="accent" tile large>
        {{ $t("shared.save") }}
      </v-btn>
    </v-container>
  </v-form>
</template>

<script>
import * as userStoreTypes from "@/store/types/user";
import { mapGetters } from "vuex";
import {
  isValidEmail,
  isValidPassword,
  isValidPhone,
  passwordMinLength,
} from "@/validation/validation";

export default {
  props: {
    userInfo: null,
  },
  data() {
    return {
      userData: {
        id: null,
        referrerId: null,
        email: null,
        phone: null,
        name: null,
        surname: null,
        accountType: null,
      },
      accountTypeOptions: [
        {
          text: this.$t("admin.userInfo.accountTypes.user"),
          value: "User",
        },
        {
          text: this.$t("admin.userInfo.accountTypes.admin"),
          value: "Admin",
        },
      ],
      emailRules: [
        (val) => !!val || this.$t("validation.requiredField"),
        (val) => isValidEmail(val) || this.$t("validation.wrongEmail"),
      ],
      phoneRules: [
        (val) => !!val || this.$t("validation.requiredField"),
        (val) => isValidPhone(val) || this.$t("validation.wrongPhone"),
      ],
      textRules: [(val) => !!val || this.$t("validation.requiredField")],
    };
  },
  methods: {
    saveUser() {
      if (this.$refs.form.validate()) {
        this.$store.dispatch(userStoreTypes.editUser, this.userInfo);
      }
    },
    findUserByUsername(value) {
      const searchData = {
        take: 30,
        skip: 0,
        userSearchFieldType: "Username",
        searchValue: value,
      };
      this.$store.dispatch(userStoreTypes.findUsers, searchData);
    },
    firstLoad() {
      const searchData = {
        take: 30,
        skip: 0,
      };
      this.$store.dispatch(userStoreTypes.findUsers, searchData);
    },
    selectNewReferrer(value) {
      const selectedReferrerId = this.users.find((u) => u.value === value).id;
      this.userInfo.referrerId = selectedReferrerId;
    },
  },
  computed: {
    isSaveError() {
      return this.$store.getters.getSaveUserInfoError;
    },
    users() {
      const users = this.$store.getters.getUsers.map((user) => {
        return {
          text: user.username,
          value: user.username,
          id: user.id,
        };
      });

      if (this.userInfo.referrer != null) {
        const selectedUser = users.find(
          (u) => u.value === this.userInfo.referrer.username
        );
        if (selectedUser == null) {
          users.push({
            text: this.userInfo.referrer.username,
            value: this.userInfo.referrer.username,
            id: this.userInfo.referrerId,
          });
        }
      }

      return users.filter((u) => u.id !== this.userInfo.id);
    },
  },
};
</script>