<template>
  <UserList class="page" />
</template>

<script>
import UserList from "@/components/admin/UserList";
export default {
  components: {
    UserList,
  },
};
</script>