<template>
  <v-form ref="form" @submit.prevent="searchUsers(true)">
    <v-container>
      <v-row align="center">
        <v-col cols="12" sm="12" md="3">
          <v-select
            :items="searchOptions"
            :label="$t('admin.users.searchBy')"
            v-model="searchData.userSearchFieldType"
          />
        </v-col>
        <v-col cols="12" sm="9" md="6">
          <v-text-field
            v-model="searchData.searchValue"
            :label="$t('admin.users.searchValue')"
          />
        </v-col>
        <v-col cols="12" sm="3" md="2">
          <v-btn type="submit" color="accent" tile large>
            {{ $t("admin.users.search") }}
          </v-btn>
        </v-col>
      </v-row>
      <v-virtual-scroll
        :items="this.users"
        :item-height="80"
        @scroll="onScrolled"
        height="800"
      >
        <template v-slot:default="{ item }">
          <v-list-item :key="item.id" :to="`/admin/user/${item.id}`">
            <v-list-item-content>
              <v-list-item-title v-text="item.username" />
              <v-list-item-subtitle>
                {{ item.email }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                {{ item.phone }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-virtual-scroll>
    </v-container>
  </v-form>
</template>

<script>
import * as userStoreTypes from "@/store/types/user";

export default {
  data() {
    return {
      searchData: {
        take: 20,
        skip: 0,
        userSearchFieldType: null,
        searchValue: null,
      },
      searchOptions: [
        {
          text: this.$t("admin.users.searchOptions.allFields"),
          value: null,
        },
        {
          text: this.$t("admin.users.searchOptions.username"),
          value: "username",
        },
        {
          text: this.$t("admin.users.searchOptions.phone"),
          value: "phone",
        },
        {
          text: this.$t("admin.users.searchOptions.email"),
          value: "email",
        },
        {
          text: this.$t("admin.users.searchOptions.id"),
          value: "id",
        },
      ],
    };
  },
  methods: {
    onScrolled(event) {
      const target = event.target;
      if (target.scrollTop + target.clientHeight >= target.scrollHeight) {
        this.searchData.skip += this.searchData.take;
        this.searchUsers(false);
      }
    },
    searchUsers(refreshList) {
      if (refreshList) {
        this.searchData.skip = 0;
        this.$store.dispatch(userStoreTypes.resetUsers);
        this.$store.dispatch(userStoreTypes.findUsers, this.searchData);
      } else {
        this.$store.dispatch(userStoreTypes.findUsers, this.searchData);
      }
    },
  },
  mounted() {
    this.searchUsers(false);
  },
  computed: {
    users() {
      return this.$store.getters.getUsers;
    },
    isError() {
      return this.$store.getters.getFindUsersError;
    },
  },
};
</script>