import { api } from './api';

class CreatePaymentData {
  userId: String;
  amount: Number;
  from: String;
  to: String
}


export const createPayment = (data: CreatePaymentData) => {
  return api().post('payment/create-payment', {
    userId: data.userId,
    amount: data.amount,
    from: data.from,
    to: data.to
  })
    .then(response => {
      let token = response.data;
      return token;
    })
    .catch(error => {
      throw error;
    });
}