// mutations
export const addUsers = 'addUsers'
export const setFindUsersError = 'setLogoutState'
export const setUsers = 'setUsers'
export const setUserInfo = 'setUserInfo'
export const setGetUserInfoError = 'setGetUserInfoError'
export const setEditUserError = 'setEditUserError'
export const setUserTotalRevenue = 'setUserTotalRevenue'
export const setUserTotalRevenueError = 'setUserTotalRevenueError'
export const setPayments = 'setPayments'

// actions
export const findUsers = 'findUsers'
export const resetUsers = 'resetUsers'
export const getUserInfo = 'getUserInfo'
export const editUser = 'editUser'
export const getUserTotalRevenue = 'getUserTotalRevenue'