import { api } from './api';

export const getMyReferrals = () => {
  return api().post('referral/get-my-referrals')
    .then(response => {
      let referrals = response.data;
      return referrals;
    })
    .catch(error => {
      throw error;
    });
}

class GetTotalRevenue {
  from: string;
  to: string;
  serviceType: string;
}

export const getTotalRevenue = (data: GetTotalRevenue) => {
  return api().post('referral/get-total-revenue', {
    from: data.from,
    to: data.to,
    serviceType: data.serviceType
  })
    .then(response => {
      let totalRevenue = response.data;
      return totalRevenue;
    })
    .catch(error => {
      throw error;
    });
}

class GetReferralRevenue {
  referralId: Number;
  from: string;
  to: string;
  serviceType: string;
}

export const getReferralRevenue = (data: GetReferralRevenue) => {
  return api().post('referral/get-referral-revenue', {
    referralId: data.referralId,
    from: data.from,
    to: data.to,
    serviceType: data.serviceType
  })
    .then(response => {
      let referralRevenue = response.data;
      return referralRevenue;
    })
    .catch(error => {
      throw error;
    });
}

class GetUserTotalRevenueData {
  userId: string;
  from: string;
  to: string;
}

export const getUserTotalRevenue = (data: GetUserTotalRevenueData) => {
  return api().post('referral/get-user-total-revenue', {
    userId: data.userId,
    from: data.from,
    to: data.to
  })
    .then(response => {
      let totalRevenue = response.data;
      return totalRevenue;
    })
    .catch(error => {
      throw error;
    });
}