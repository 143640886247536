import { findUsers, getUserInfo, editUser } from '@/api/user.ts';
import { getUserTotalRevenue } from '@/api/referral.ts';
import * as types from '../types/user'

const state = {
  users: [],
  findUsersError: false,
  userDetails: {},
  payments: [],
  getUserInfoError: false,
  editUserError: false,
  userTotalRevenue: {},
  getUserTotalRevenueError: false
};

const mutations = {
  [types.addUsers]: (state, users) => {
    state.users.push(...users);
  },
  [types.setFindUsersError]: (state, isError) => {
    state.findUsersError = isError;
  },
  [types.setUsers]: (state, users) => {
    state.users = users;
  },
  [types.setUserInfo]: (state, userInfo) => {
    state.userDetails = userInfo;
  },
  [types.setGetUserInfoError]: (state, isError) => {
    state.getUserInfoError = isError;
  },
  [types.setEditUserError]: (state, isError) => {
    state.editUserError = isError;
  },
  [types.setGetUserInfoError]: (state, isError) => {
    state.getUserTotalRevenueError = isError;
  },
  [types.setUserTotalRevenue]: (state, userTotalRevenue) => {
    state.userTotalRevenue = userTotalRevenue;
  },
  [types.setPayments]: (state, payments) => {
    state.payments = payments;
  }
}

const actions = {
  [types.findUsers]: ({ commit }, payload,) => {
    findUsers(payload)
      .then(users => {
        commit(types.addUsers, users);
      })
      .catch(() => {
        setTimeout(() => {
          commit(types.setFindUsersError, true);
        }, 500);
        setTimeout(() => {
          commit(types.setFindUsersError, false);
        }, 3000);
      })
  },
  [types.resetUsers]: ({ commit }) => {
    commit(types.setUsers, []);
  },
  [types.getUserInfo]: ({ commit }, payload) => {
    getUserInfo(payload)
      .then(userInfo => {
        commit(types.setUserInfo, userInfo.user);
        commit(types.setPayments, userInfo.payments);
      })
      .catch(() => {
        setTimeout(() => {
          commit(types.setGetUserInfoError, true);
        }, 500);
        setTimeout(() => {
          commit(types.setGetUserInfoError, false);
        }, 3000);
      })
  },
  [types.editUser]: ({ commit }, payload) => {
    editUser(payload)
      .then(userInfo => {
        if (userInfo != null) {
          commit(types.setUserInfo, userInfo);
        }
        else {
          throw new Error('Error');
        }
      })
      .catch(() => {
        setTimeout(() => {
          commit(types.setEditUserError, true);
        }, 500);
        setTimeout(() => {
          commit(types.setEditUserError, false);
        }, 5000);
      })
  },
  [types.getUserTotalRevenue]: ({ commit }, payload) => {
    getUserTotalRevenue(payload)
      .then(totalRevenue => {
        commit(types.setUserTotalRevenue, totalRevenue);
      })
      .catch(() => {
        setTimeout(() => {
          commit(types.setUserTotalRevenueError, true);
        }, 500);
        setTimeout(() => {
          commit(types.setUserTotalRevenueError, false);
        }, 5000);
      })
  }
}

const getters = {
  getUsers: (state) => state.users,
  getFindUsersError: (state) => state.findUsersError,
  getUserInfo: (state) => state.userDetails,
  getGetUserInfoError: (state) => state.getUserInfoError,
  getSaveUserInfoError: (state) => state.editUserError,
  getUserTotalRevenue: (state) => state.userTotalRevenue,
  getUserTotalRevenueError: (state) => state.getUserTotalRevenueError,
  getPayments: (state) => state.payments
}

export default {
  state,
  actions,
  mutations,
  getters
}