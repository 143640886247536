<template>
  <v-form
    ref="form"
    @submit.prevent="resetPassword"
    lazy-validation
    class="container"
  >
    <v-container>
      <v-alert
        type="success"
        dismissible
        v-show="resettedPassword != '' && resettedPassword != null"
      >
        {{ $t("admin.resetPassword.success", { password: resettedPassword }) }}
      </v-alert>

      <v-alert type="error" dismissible v-show="resettedPassword == ''">
        {{ $t("admin.resetPassword.fail") }}
      </v-alert>

      <v-alert type="error" dismissible v-show="isError">
        {{ $t("admin.resetPassword.error") }}
      </v-alert>

      <v-text-field
        v-model="resetPasswordData.email"
        autofocus
        :label="$t('user.email')"
        required
        :rules="emailRules"
        type="email"
      />

      <spinner :display="isLoading" />

      <v-btn class="mt-8" type="submit" color="accent" tile large>
        {{ $t("admin.resetPassword.reset") }}
      </v-btn>
    </v-container>
  </v-form>
</template>

<script>
import { isValidEmail } from "@/validation/validation";
import * as accountStoreTypes from "@/store/types/account";
import Spinner from "@/components/shared/Spinner";

export default {
  components: {
    Spinner,
  },
  data() {
    return {
      emailRules: [
        (val) => !!val || this.$t("validation.requiredField"),
        (val) => isValidEmail(val) || this.$t("validation.wrongEmail"),
      ],
      resetPasswordData: {
        email: null,
      },
    };
  },
  methods: {
    resetPassword() {
      if (this.$refs.form.validate()) {
        this.$store.dispatch(
          accountStoreTypes.resetPassword,
          this.resetPasswordData
        );

        this.$refs.form.reset();
      }
    },
  },
  computed: {
    resettedPassword() {
      return this.$store.getters.getResettedPassword;
    },
    isLoading() {
      return this.$store.getters.getIsResetPasswordLoading;
    },
    isError() {
      return this.$store.getters.getIsResetPasswordError;
    },
  },
};
</script>