import { getMyReferrals, getTotalRevenue, getReferralRevenue } from "@/api/referral.ts";
import * as types from '../types/referral';

const namespaced = true;

const state = {
  referrals: [],
  isLoadingGetReferrals: false,
  isGetReferralsSuccess: false,
  isGetReferralsError: false,

  isLoadingGetTotalRevenue: false,
  isGetTotalRevenueSuccess: false,
  isGetTotalRevenueError: false,

  isLoadingGetReferralRevenue: false,
  isGetReferralRevenueSuccess: false,
  isGetReferralRevenueError: false,
  totalRevenue: 0,
  revenues: {}
};

const mutations = {
  setIsLoadingGetReferrals: (state, payload) => {
    state.isLoadingGetReferrals = payload;
  },
  setIsGetReferralsError: (state, payload) => {
    state.isGetReferralsError = payload;
  },
  setReferrals: (state, payload) => {
    state.referrals = payload;
  },

  setIsLoadingGetTotalRevenue: (state, payload) => {
    state.isLoadingGetTotalRevenue = payload;
  },
  setIsGetTotalRevenueError: (state, payload) => {
    state.isGetTotalRevenueError = payload;
  },

  setIsLoadingGetReferralRevenue: (state, payload) => {
    state.isLoadingGetReferralRevenue = payload;
  },
  setIsGetReferralRevenueError: (state, payload) => {
    state.isGetReferralRevenueError = payload;
  },

  setTotalRevenue: (state, payload) => {
    state.totalRevenue = payload;
  },
  setRevenues: (state, payload) => {
    state.revenues = payload;
  },
};

const actions = {
  getMyReferrals: ({ commit }) => {
    commit(types.setIsLoadingGetReferrals, true);
    getMyReferrals()
      .then((response) => {
        setTimeout(() => {
          commit(types.setReferrals, response.referrals);
        }, 500);
      })
      .catch(() => {
        setTimeout(() => {
          commit(types.setIsGetReferralsError, true);
        }, 500);
        setTimeout(() => {
          commit(types.setIsGetReferralsError, false);
        }, 3000);
      })
      .finally(() => {
        setTimeout(() => {
          commit(types.setIsLoadingGetReferrals, false);
        }, 500);
      });
  },
  getTotalRevenue: ({ commit }, payload) => {
    commit(types.setIsLoadingGetTotalRevenue, true);
    getTotalRevenue(payload)
      .then((response) => {
        setTimeout(() => {
          commit(types.setRevenues, response.revenues);
          commit(types.setTotalRevenue, response.totalRevenue);
        }, 500);
      })
      .catch(() => {
        setTimeout(() => {
          commit(types.setIsGetTotalRevenueError, true);
        }, 500);
        setTimeout(() => {
          commit(types.setIsGetTotalRevenueError, false);
        }, 3000);
      })
      .finally(() => {
        setTimeout(() => {
          commit(types.setIsLoadingGetTotalRevenue, false);
        }, 500);
      });
  },
  getReferralRevenue: ({ commit }, payload) => {
    commit(types.setIsLoadingGetReferralRevenue, true);
    getReferralRevenue(payload)
      .then((response) => {
        setTimeout(() => {
          commit(types.setRevenues, response.revenues);
          commit(types.setTotalRevenue, response.totalRevenue);
        }, 500);
      })
      .catch(() => {
        setTimeout(() => {
          commit(types.setIsGetReferralRevenueError, true);
        }, 500);
        setTimeout(() => {
          commit(types.setIsGetReferralRevenueError, false);
        }, 3000);
      })
      .finally(() => {
        setTimeout(() => {
          commit(types.setIsLoadingGetReferralRevenue, false);
        }, 500);
      });
  },
};

const getters = {
  getIsLoadingGetReferrals: (state) => state.isLoadingGetReferrals,
  getIsGetReferralsError: (state) => state.isGetReferralsError,
  getReferrals: (state) => state.referrals,

  getIsLoadingGetTotalRevenue: (state) => state.isLoadingGetTotalRevenue,
  getIsGetTotalRevenueError: (state) => state.isGetTotalRevenueError,

  getIsLoadingGetReferralRevenue: (state) => state.isLoadingGetReferralRevenue,
  getIsGetReferralRevenueError: (state) => state.isGetReferralRevenueError,

  getTotalRevenue: (state) => state.totalRevenue,
  getRevenues: (state) => state.revenues,
};

export default {
  state,
  actions,
  mutations,
  getters
}