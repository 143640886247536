import { uploadReport } from '@/api/report.ts';
import * as types from '../types/report';

const state = {
  isUploadReportError: false,
  isUploadReportLoading: false,
  isUploadReportSuccess: false
};

const mutations = {
  setIsUploadReportLoading: (state, payload) => {
    state.isUploadReportLoading = payload;
  },
  setIsUploadReportSuccess: (state, payload) => {
    state.isUploadReportSuccess = payload;
  },
  setIsUploadReportError: (state, payload) => {
    state.isUploadReportError = payload;
  },
};

const actions = {
  uploadReport: ({ commit }, payload) => {
    commit(types.setIsUploadReportLoading, true);
    uploadReport(payload)
      .then(() => {
        setTimeout(() => {
          commit(types.setIsUploadReportSuccess, true);
        }, 500);
        setTimeout(() => {
          commit(types.setIsUploadReportSuccess, false);
        }, 3000);
      })
      .catch(() => {
        setTimeout(() => {
          commit(types.setIsUploadReportError, true);
        }, 500);
        setTimeout(() => {
          commit(types.setIsUploadReportError, false);
        }, 3000);
      })
      .finally(() => {
        setTimeout(() => {
          commit(types.setIsUploadReportLoading, false);
        }, 500);
      });
  }
};

const getters = {
  getIsUploadReportError: (state) => state.isUploadReportError,
  getIsUploadReportLoading: (state) => state.isUploadReportLoading,
  getIsUploadReportSuccess: (state) => state.isUploadReportSuccess
};

export default {
  state,
  actions,
  mutations,
  getters
}