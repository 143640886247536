<template>
  <v-container>
    <v-alert type="error" dismissible v-show="getIsCreatePaymentError === true">
      {{ $t("admin.userInfo.createPayment.error") }}
    </v-alert>

    <v-alert
      type="success"
      dismissible
      v-show="getIsCreatePaymentError === false"
    >
      {{ $t("admin.userInfo.createPayment.success") }}
    </v-alert>

    <v-row>
      <v-col sm="12">
        <WeekSelector :callback="onWeekSelected" />
      </v-col>
      <v-col sm="12">
        <v-text-field
          readonly
          :value="calculateRevenues()"
          :label="$t('admin.userInfo.createPayment.rewardAmount')"
        />
      </v-col>

      <v-btn
        @click="createPayment()"
        color="accent"
        tile
        large
        :disabled="amount === 0 || wasPaid"
      >
        {{ $t("admin.userInfo.createPayment.pay") }}
      </v-btn>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import WeekSelector from "@/components/shared/WeekSelector";
import * as userStoreTypes from "@/store/types/user";
import * as paymentStoreTypes from "@/store/types/payment";

export default {
  components: {
    WeekSelector,
  },
  props: {
    payments: null,
    userInfo: null,
  },
  data() {
    return {
      from: null,
      to: null,
      amount: 0,
      wasPaid: false,
    };
  },
  methods: {
    onWeekSelected(dates) {
      this.wasPaid = false;
      this.from = dates[0];
      this.to = dates[1];

      const data = {
        from: dates[0],
        to: dates[1],
        userId: this.userInfo.id,
      };

      this.$store.dispatch(userStoreTypes.getUserTotalRevenue, data);

      const existingPayment = this.payments.find((payment) =>
        this.compareDates(payment.from, dates[0])
      );
      if (existingPayment != null) {
        this.wasPaid = true;
      }
    },
    compareDates(firstDate, secondDate) {
      const firstDateTimestamp = this.$moment(firstDate).format("X");
      const secondDateTimestamp = this.$moment(secondDate).format("X");

      return firstDateTimestamp === secondDateTimestamp;
    },
    calculateRevenues() {
      const revenues = this.getUserTotalRevenue.revenues;

      if (revenues == null) {
        this.amount = 0;
      } else {
        const revenuesValues = Object.values(revenues);
        this.amount = revenuesValues.reduce((a, b) => a + b, 0);
      }

      return this.amount;
    },
    createPayment() {
      const data = {
        userId: this.userInfo.id,
        amount: this.amount,
        from: this.from,
        to: this.to,
      };
      this.$store.dispatch(paymentStoreTypes.createPayment, data);

      this.wasPaid = true;
    },
  },
  computed: {
    ...mapGetters(["getUserTotalRevenue"]),
    ...mapGetters(["getIsCreatePaymentError"]),
  },
};
</script>