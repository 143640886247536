<template>
  <div v-show="this.display">
    <spinner />
  </div>
</template>

<script>
import Spinner from "vue-loading-spinner/src/components/Circle";

export default {
  components: {
    Spinner,
  },
  props: {
    display: false,
  },
};
</script>