<template>
  <SignupForm />
</template>

<script>
import SignupForm from "@/components/signup/SignupForm";

export default {
  components: {
    SignupForm,
  },
};
</script>