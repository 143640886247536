import { api } from './api';

enum UserSearchFieldType {
  Username = 0,
  Phone = 1,
  Email = 2,
  Id = 3
};

class FindUsersData {
  take: number;
  skip: number;
  userSearchFieldType: UserSearchFieldType;
  searchValue: string;
}

export const findUsers = (data: FindUsersData) => {
  return api().post('user/find-users', data)
    .then(response => {
      let users = response.data.users;
      return users;
    })
    .catch(error => {
      throw error;
    });
}

export const getUserInfo = (userId: number) => {
  return api().post('user/get-user-info', { userId })
    .then(response => {
      const user = response.data.user;
      const payments = response.data.payments;
      return { user, payments };
    })
    .catch(error => {
      throw error;
    });
}

enum UserSearchFieldType {
  Guest = 0,
  User = 1,
  Admin = 2
};

class EditUserData {
  email: string;
  phone: string;
  name: string;
  surname: string;
  accountType: UserSearchFieldType;
  referrerId: number;
}

export const editUser = (data: EditUserData) => {
  return api().post('user/edit-user-info', data)
    .then(response => {
      let modifiedUser = response.data.user;
      return modifiedUser;
    })
    .catch(error => {
      throw error;
    });
}
