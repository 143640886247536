<template>
  <v-form ref="form" @submit.prevent="upload" lazy-validation class="container">
    <v-container>
      <v-alert type="error" dismissible v-show="isUplaodReportError === true">
        {{ $t("admin.failToUpload") }}
      </v-alert>

      <v-alert
        type="success"
        dismissible
        v-show="isUplaodReportSuccess === true"
      >
        {{ $t("admin.reportWasUploaded") }}
      </v-alert>

      <v-radio-group v-model="radioGroup" mandatory row>
        <v-radio :label="this.uber.label" :value="this.uber.value" />
        <v-radio
          :label="this.boltDriver.label"
          :value="this.boltDriver.value"
        />
        <v-radio
          :label="this.boltDelivery.label"
          :value="this.boltDelivery.value"
        />
      </v-radio-group>
      <v-file-input
        accept="csv/*"
        :label="this.$t('admin.uploadReport')"
        v-model="report"
        :rules="requiredRules"
      />

      <WeekSelector
        :callback="onWeekSelected"
        :valueLabel="true"
        class="mb-4"
        ref="weekSelector"
      />

      <spinner :display="isLoading" />
      <v-btn class="mt-8" type="submit" color="accent" tile large>
        {{ $t("shared.send") }}
      </v-btn>
    </v-container>
  </v-form>
</template>

<script>
import * as reportStoreTypes from "@/store/types/report";
import Spinner from "@/components/shared/Spinner";
import WeekSelector from "@/components/shared/WeekSelector";

export default {
  components: {
    Spinner,
    WeekSelector,
  },
  data() {
    return {
      uber: {
        label: this.$t("shared.uber"),
        value: "uber",
      },
      boltDriver: {
        label: this.$t("shared.boltDriver"),
        value: "boltDriver",
      },
      boltDelivery: {
        label: this.$t("shared.boltDelivery"),
        value: "boltDelivery",
      },
      radioGroup: null,
      report: null,
      requiredRules: [(val) => !!val || this.$t("validation.requiredField")],
      weekDates: [],
    };
  },
  methods: {
    onWeekSelected(dates) {
      this.weekDates[0] = dates[0];
      this.weekDates[1] = dates[1];
    },
    upload() {
      if (this.$refs.form.validate()) {
        let partnerType;
        let serviceType;
        let from;
        let to;
        switch (this.radioGroup) {
          case this.uber.value:
            partnerType = "uber";
            from = this.weekDates[0];
            to = this.weekDates[1];
            serviceType = null;
            break;
          case this.boltDriver.value:
            partnerType = "bolt";
            serviceType = "driver";
            from = this.weekDates[0];
            to = this.weekDates[1];
            break;
          case this.boltDelivery.value:
            partnerType = "bolt";
            serviceType = "delivery";
            from = this.weekDates[0];
            to = this.weekDates[1];
            break;
        }

        const reportData = {
          partnerType: partnerType,
          serviceType: serviceType,
          report: this.report,
          from: from,
          to: to,
        };
        this.$store.dispatch(reportStoreTypes.uploadReport, reportData);
        this.$refs.form.reset();
        this.$refs.weekSelector.resetWeekSelector();
      }
    },
  },
  computed: {
    isUplaodReportError() {
      return this.$store.getters.getIsUploadReportError;
    },
    isLoading() {
      return this.$store.getters.getIsUploadReportLoading;
    },
    isUplaodReportSuccess() {
      return this.$store.getters.getIsUploadReportSuccess;
    },
  },
};
</script>