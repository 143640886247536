<template>
  <v-form
    ref="form"
    @submit.prevent="changePassword"
    lazy-validation
    class="changePasswordForm d-flex align-center"
  >
    <v-container class="formContainer d-flex flex-column align-center">
      <h2 class="primary--text">{{ $t("changePassword.title") }}</h2>

      <v-alert
        type="error"
        dismissible
        v-show="changePasswordState === 'error'"
      >
        {{ $t("shared.serverError") }}
      </v-alert>

      <v-alert type="error" dismissible v-show="changePasswordState === false">
        {{ $t("changePassword.fail") }}
      </v-alert>

      <v-alert type="success" dismissible v-show="changePasswordState === true">
        {{ $t("changePassword.success") }}
      </v-alert>

      <v-text-field
        v-model="changePasswordData.oldPassword"
        :append-icon="passwordVisibility ? 'mdi-eye' : 'mdi-eye-off'"
        required
        :type="passwordVisibility ? 'text' : 'password'"
        :label="$t('changePassword.oldPassword')"
        @click:append="passwordVisibility = !passwordVisibility"
        :rules="passwordRules"
        class="mt-8 textField"
      />

      <v-text-field
        v-model="changePasswordData.newPassword"
        :append-icon="passwordVisibility ? 'mdi-eye' : 'mdi-eye-off'"
        required
        :type="passwordVisibility ? 'text' : 'password'"
        :label="$t('changePassword.newPassword')"
        @click:append="passwordVisibility = !passwordVisibility"
        :rules="passwordRules"
        class="mt-8 textField"
        @change="() => this.$refs.repeatNewPassword.validate()"
      />

      <v-text-field
        v-model="repeatNewPassword"
        :append-icon="passwordVisibility ? 'mdi-eye' : 'mdi-eye-off'"
        required
        :type="passwordVisibility ? 'text' : 'password'"
        :label="$t('changePassword.repeatNewPassword')"
        @click:append="passwordVisibility = !passwordVisibility"
        :rules="passwordRules.concat(repeatPasswordRules)"
        class="mt-8 textField"
        ref="repeatNewPassword"
      />

      <spinner :display="isLoading" />

      <v-btn class="mt-8" type="submit" color="accent" tile large>
        {{ $t("shared.send") }}
      </v-btn>
    </v-container>
  </v-form>
</template>

<script>
import { isValidPassword, passwordMinLength } from "@/validation/validation";
import * as accountStoreTypes from "@/store/types/account";
import Spinner from "@/components/shared/Spinner";

export default {
  components: {
    Spinner,
  },
  data() {
    return {
      passwordRules: [
        (val) => !!val || this.$t("validation.requiredField"),
        (val) =>
          (!!val && val.length >= passwordMinLength) ||
          this.$t("validation.wrongPasswordLength", { x: passwordMinLength }),
      ],
      repeatPasswordRules: [
        (val) =>
          (!!val &&
            val === this.changePasswordData.newPassword &&
            this.changePasswordData.newPassword === this.repeatNewPassword) ||
          this.$t("changePassword.passwordShouldBeSame"),
      ],
      changePasswordData: {
        oldPassword: null,
        newPassword: null,
      },
      repeatNewPassword: null,
      passwordVisibility: false,
    };
  },
  methods: {
    changePassword() {
      if (this.$refs.form.validate()) {
        this.$store.dispatch(
          accountStoreTypes.changePassword,
          this.changePasswordData
        );

        this.$refs.form.reset();
      }
    },
  },
  computed: {
    changePasswordState() {
      return this.$store.getters.getChangePasswordState;
    },
    isLoading() {
      return this.$store.getters.getIsChangePasswordLoading;
    },
  },
};
</script>

<style lang="scss" scoped>
.changePasswordForm {
  height: 100vh;
}

.formContainer {
  width: 300px;
}

.textField {
  width: 100%;
}

.logo {
  height: 100px;
}
</style>