<template>
  <AdminAppBar v-if="this.isAdmin" />
  <DefaultAppBar v-else />
</template>

<script>
import AdminAppBar from "./AdminAppBar";
import DefaultAppBar from "./DefaultAppBar";

export default {
  components: {
    AdminAppBar,
    DefaultAppBar,
  },
  data() {
    return {
      isAdmin: this.$router.history.current.path.includes("/admin"),
    };
  },
  methods: {
    isAdminRoute(path) {
      this.isAdmin = path.includes("/admin");
    },
  },
  watch: {
    $route(to, from) {
      this.isAdminRoute(to.path);
      this.$forceUpdate();
    },
  },
};
</script>