<template>
  <v-form
    ref="form"
    @submit.prevent="signup"
    lazy-validation
    class="signupForm d-flex align-center"
  >
    <v-container class="formContainer d-flex flex-column align-center">
      <img class="logo" src="@/assets/images/logo.png" alt="Logo" />

      <v-alert type="error" dismissible v-show="signupError === 'Error'">
        {{ $t("signup.signupError") }}
      </v-alert>

      <v-alert
        type="error"
        dismissible
        v-show="signupState === 'AccountAlreadyExists'"
      >
        {{ $t("signup.accountExists") }}
      </v-alert>

      <v-alert
        type="error"
        dismissible
        v-show="signupState === 'WrongReferrerNumber'"
      >
        {{ $t("signup.wrongReferrer") }}
      </v-alert>

      <v-alert type="success" dismissible v-show="signupState === 'Success'">
        {{ $t("signup.signupSuccess") }}
      </v-alert>

      <v-text-field
        v-model="signupData.email"
        autofocus
        :label="$t('user.email')"
        required
        :rules="emailRules"
        type="email"
        class="textField"
      />
      <v-text-field
        v-model="signupData.phone"
        :label="$t('user.phone')"
        :rules="phoneRules"
        type="phone"
        class="textField"
        v-mask="'+48-###-###-###'"
      />
      <v-text-field
        v-model="signupData.password"
        :label="$t('user.password')"
        required
        :rules="passwordRules"
        :append-icon="passwordVisibility ? 'mdi-eye' : 'mdi-eye-off'"
        :type="passwordVisibility ? 'text' : 'password'"
        @click:append="passwordVisibility = !passwordVisibility"
        class="textField"
      />
      <v-text-field
        v-model="signupData.againPassword"
        :label="$t('user.againPassword')"
        required
        :rules="passwordRules"
        :append-icon="passwordVisibility ? 'mdi-eye' : 'mdi-eye-off'"
        :type="passwordVisibility ? 'text' : 'password'"
        @click:append="passwordVisibility = !passwordVisibility"
        class="textField"
      />
      <v-text-field
        v-model="signupData.name"
        :label="$t('user.name')"
        type="text"
        required
        :rules="textRules"
        class="textField"
      />
      <v-text-field
        v-model="signupData.surname"
        :label="$t('user.surname')"
        type="text"
        required
        :rules="textRules"
        class="textField"
      />
      <v-text-field
        v-model="signupData.referrerPhone"
        :label="$t('user.referrerPhone')"
        type="phone"
        required
        class="textField"
        v-mask="'+48-###-###-###'"
        :rules="phoneRules"
      />

      <spinner :display="isLoading" />

      <v-btn class="mt-8" type="submit" tile large color="accent">
        {{ $t("signup.signup") }}
      </v-btn>

      <v-btn class="backButton" to="/login" small text color="accent">
        {{ $t("shared.back") }}
      </v-btn>
    </v-container>
  </v-form>
</template>

<script>
import {
  isValidEmail,
  isValidPhone,
  passwordMinLength,
} from "@/validation/validation";
import * as accountStoreTypes from "@/store/types/account";
import Spinner from "@/components/shared/Spinner";

export default {
  components: {
    Spinner,
  },
  data() {
    return {
      emailRules: [
        (val) => !!val || this.$t("validation.requiredField"),
        (val) => isValidEmail(val) || this.$t("validation.wrongEmail"),
      ],
      phoneRules: [
        (val) => !!val || this.$t("validation.requiredField"),
        (val) => isValidPhone(val) || this.$t("validation.wrongPhone"),
      ],
      passwordRules: [
        (val) => !!val || this.$t("validation.requiredField"),
        (val) =>
          (!!val && val.length >= passwordMinLength) ||
          this.$t("validation.wrongPasswordLength", { x: passwordMinLength }),
      ],
      textRules: [(val) => !!val || this.$t("validation.requiredField")],
      signupData: {
        email: null,
        password: null,
        againPassword: null,
        phone: null,
        name: null,
        surname: null,
        referrerPhone: null,
      },
      passwordVisibility: false,
    };
  },
  methods: {
    signup() {
      if (this.$refs.form.validate()) {
        this.$store.dispatch(accountStoreTypes.signup, this.signupData);
      }
    },
  },
  computed: {
    signupError() {
      return this.$store.getters.getSignupError;
    },
    isLoading() {
      return this.$store.getters.getIsSignupLoading;
    },
    signupState() {
      return this.$store.getters.getSignupState;
    },
  },
};
</script>

<style lang="scss" scoped>
.signupForm {
  height: 100vh;
}

.logo {
  height: 100px;
}

.formContainer {
  width: 300px;
}

.textField {
  width: 100%;
}

.backButton {
  margin-top: 20px;
}
</style>