<template>
  <UploadReport class="page" />
</template>

<script>
import UploadReport from "@/components/admin/UploadReport";
export default {
  components: {
    UploadReport,
  },
};
</script>