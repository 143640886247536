<template>
  <Referrals serviceType="Delivery" />
</template>

<script>
import Referrals from "./Referrals";

export default {
  components: {
    Referrals,
  },
};
</script>