<template>
  <v-form
    ref="form"
    @submit.prevent="signup"
    lazy-validation
    class="signupForm d-flex align-center"
  >
    <v-container class="formContainer d-flex flex-column align-center">
      <v-alert type="error" dismissible v-show="signupError === 'Error'">
        {{ $t("signup.signupError") }}
      </v-alert>

      <v-alert
        type="error"
        dismissible
        v-show="signupState === 'AccountAlreadyExists'"
      >
        {{ $t("signup.accountExists") }}
      </v-alert>

      <v-alert
        type="error"
        dismissible
        v-show="signupState === 'WrongReferrerNumber'"
      >
        {{ $t("signup.wrongReferrer") }}
      </v-alert>

      <v-alert type="success" dismissible v-show="signupState === 'Success'">
        {{ $t("signup.signupSuccess") }}
      </v-alert>

      <v-text-field
        v-model="signupData.email"
        autofocus
        :label="$t('user.email')"
        required
        :rules="emailRules"
        type="email"
        class="textField"
      />
      <v-text-field
        v-model="signupData.phone"
        :label="$t('user.phone')"
        :rules="phoneRules"
        type="phone"
        class="textField"
        v-mask="'+48-###-###-###'"
      />
      <v-text-field
        v-model="signupData.password"
        :label="$t('user.password')"
        required
        :rules="passwordRules"
        :append-icon="passwordVisibility ? 'mdi-eye' : 'mdi-eye-off'"
        :type="passwordVisibility ? 'text' : 'password'"
        @click:append="passwordVisibility = !passwordVisibility"
        class="textField"
      />
      <v-text-field
        v-model="signupData.againPassword"
        :label="$t('user.againPassword')"
        required
        :rules="passwordRules"
        :append-icon="passwordVisibility ? 'mdi-eye' : 'mdi-eye-off'"
        :type="passwordVisibility ? 'text' : 'password'"
        @click:append="passwordVisibility = !passwordVisibility"
        class="textField"
      />
      <v-text-field
        v-model="signupData.name"
        :label="$t('user.name')"
        type="text"
        required
        :rules="textRules"
        class="textField"
      />
      <v-text-field
        v-model="signupData.surname"
        :label="$t('user.surname')"
        type="text"
        required
        :rules="textRules"
        class="textField"
      />
      <v-autocomplete
        :items="users"
        v-model="signupData.referrerPhone"
        @keyup="findUserByUsername($event.target.value)"
        @focus="firstLoad"
        :label="$t('admin.userInfo.referrerUsername')"
        :rules="textRules"
      />

      <spinner :display="isLoading" />

      <v-btn class="mt-8" type="submit" tile large color="accent">
        {{ $t("admin.registerUser.register") }}
      </v-btn>
    </v-container>
  </v-form>
</template>

<script>
import {
  isValidEmail,
  isValidPhone,
  passwordMinLength,
} from "@/validation/validation";
import * as accountStoreTypes from "@/store/types/account";
import * as userStoreTypes from "@/store/types/user";
import Spinner from "@/components/shared/Spinner";

export default {
  components: {
    Spinner,
  },
  data() {
    return {
      emailRules: [
        (val) => !!val || this.$t("validation.requiredField"),
        (val) => isValidEmail(val) || this.$t("validation.wrongEmail"),
      ],
      phoneRules: [
        (val) => !!val || this.$t("validation.requiredField"),
        (val) => isValidPhone(val) || this.$t("validation.wrongPhone"),
      ],
      passwordRules: [
        (val) => !!val || this.$t("validation.requiredField"),
        (val) =>
          (!!val && val.length >= passwordMinLength) ||
          this.$t("validation.wrongPasswordLength", { x: passwordMinLength }),
      ],
      textRules: [(val) => !!val || this.$t("validation.requiredField")],
      signupData: {
        email: null,
        password: null,
        againPassword: null,
        phone: null,
        name: null,
        surname: null,
        referrerPhone: null,
      },
      referrer: {
        value: null,
        text: null,
        id: 0,
      },
      passwordVisibility: false,
    };
  },
  methods: {
    signup() {
      if (this.$refs.form.validate()) {
        this.$store.dispatch(accountStoreTypes.signup, this.signupData);
      }
    },
    findUserByUsername(value) {
      const searchData = {
        take: 30,
        skip: 0,
        userSearchFieldType: "Username",
        searchValue: value,
      };
      this.$store.dispatch(userStoreTypes.findUsers, searchData);
    },
    firstLoad() {
      const searchData = {
        take: 30,
        skip: 0,
      };
      this.$store.dispatch(userStoreTypes.findUsers, searchData);
    },
  },
  computed: {
    signupError() {
      return this.$store.getters.getSignupError;
    },
    isLoading() {
      return this.$store.getters.getIsSignupLoading;
    },
    signupState() {
      return this.$store.getters.getSignupState;
    },
    users() {
      const users = this.$store.getters.getUsers.map((user) => {
        return {
          text: user.username,
          value: user.phone,
        };
      });

      return users;
    },
  },
};
</script>

<style lang="scss" scoped>
.signupForm {
  height: 100vh;
}

.formContainer {
  width: 300px;
}

.textField {
  width: 100%;
}
</style>