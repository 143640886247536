import Vue from 'vue'
import VueRouter from 'vue-router'
import paths from './paths'
import store from '@/store/index'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: paths
})

const isPathAllowedWithoutToken = (path) => {
  return path === '/login' || path === '/signup' || path === '/recover';
};

router.beforeEach((to, _, next) => {
  var isLoggedIn = store.getters.getIsLoggedIn;
  var isUserAdmin = store.getters.getIsAdmin;
  if (isLoggedIn === false && isPathAllowedWithoutToken(to.path) === false) {
    next('/login');
    return;
  }
  else if (isLoggedIn === true && isPathAllowedWithoutToken(to.path)) {
    next('/');
    return;
  }
  else if (isUserAdmin === false && to.path.includes('/admin')) {
    next('/');
    return;
  }
  next();
})

export default router
