<template>
  <ChangePasswordForm />
</template>

<script>
import ChangePasswordForm from "@/components/changePassword/ChangePasswordForm";

export default {
  components: {
    ChangePasswordForm,
  },
};
</script>