export const passwordMinLength = 10;

export const isValidEmail = (email) => {
  if (email == null) return false;

  const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  return emailRegex.test(email);
}

export const isValidPassword = (password) => {
  if (password == null) return false;

  return password.length >= passwordMinLength;
}

export const isValidPhone = (phone) => {
  if (phone == null) return false;

  return phone.length === 15;
}