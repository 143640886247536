import Vue from 'vue';
import Vuex from 'vuex';

import account from './modules/account';
import report from "./modules/report";
import referral from "./modules/referral";
import user from "./modules/user";
import payment from "./modules/payment";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    account,
    report,
    referral,
    user,
    payment
  },
})
