<template>
  <DriverReferrals class="page" />
</template>

<script>
import DriverReferrals from "@/components/referrals/Driver";
export default {
  components: {
    DriverReferrals,
  },
};
</script>