import { api } from './api';

class ReportData {
  partnerType: string;
  serviceType: string;
  report: File;
  from: string;
  to: string;
}

export const uploadReport = (data: ReportData) => {
  const formData = new FormData();
  formData.append('partnerType', data.partnerType);
  formData.append('serviceType', data.serviceType);
  formData.append('report', data.report);
  formData.append('from', data.from);
  formData.append('to', data.to);
  return api().post('report/upload-report', formData)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw error;
    });
}