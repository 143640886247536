<template>
  <RecoverPassword class="page" />
</template>

<script>
import RecoverPassword from "@/components/recover/RecoverPassword";
export default {
  components: {
    RecoverPassword,
  },
};
</script>