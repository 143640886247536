<template>
  <v-app dark>
    <div class="app">
      <main role="main" class="main">
        <AppBar v-if="isLoggedIn" />
        <router-view></router-view>
      </main>
    </div>
  </v-app>
</template>

<script>
import AppBar from "./components/_appbar/AppBar";

export default {
  components: {
    AppBar,
  },
  name: "app",
  computed: {
    isLoggedIn() {
      return this.$store.getters.getIsLoggedIn;
    },
  },
  watch: {
    isLoggedIn(status) {
      if (status == true) {
        this.$router.push("/");
      } else {
        this.$router.push("/login");
      }
    },
  },
};
</script>

<style lang="scss">
.app {
  background-color: #edf3f9;
}

.page {
  height: calc(100vh - 64px);
  margin-top: 64px;
}

@media screen and (max-width: $lg_max) {
  .page {
    height: calc(100% - 64px);
    margin-top: 64px;
  }
}

@media screen and (min-width: $xs_min) and (max-width: $md_min) {
  html {
    font-size: 20px !important;
  }
}
</style>