import { createPayment } from '@/api/payment.ts';
import * as types from '../types/payment';

const state = {
  isCreatePaymentError: null
};

const mutations = {
  [types.setIsCreatePaymentError]: (state, isError) => {
    state.isCreatePaymentError = isError;
  },
}

const actions = {
  [types.createPayment]: ({ commit }, payload) => {
    createPayment(payload)
      .then(() => {
        commit(types.setIsCreatePaymentError, false);
      })
      .catch(() => {
        commit(types.setIsCreatePaymentError, true);
      })
      .finally(() => {
        setTimeout(() => {
          commit(types.setIsCreatePaymentError, null);
        }, 3000);
      });
  }
}

const getters = {
  getIsCreatePaymentError: (state) => state.isCreatePaymentError,
}

export default {
  state,
  actions,
  mutations,
  getters
}