<template>
  <v-container>
    <v-tabs v-model="tab">
      <v-tab>
        {{ $t("admin.userInfo.userInfo") }}
      </v-tab>
      <v-tab>
        {{ $t("admin.userInfo.revenue.title") }}
      </v-tab>
      <v-tab>
        {{ $t("admin.userInfo.createPayment.title") }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item>
        <EditUserInfo :userInfo="getUserInfo" />
      </v-tab-item>
      <v-tab-item>
        <UserRevenue :payments="getPayments" />
      </v-tab-item>
      <v-tab-item>
        <CreatePayment :userInfo="getUserInfo" :payments="getPayments" />
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import EditUserInfo from "./EditUserInfo";
import UserRevenue from "./UserRevenue";
import CreatePayment from "./CreatePayment";
import * as userStoreTypes from "@/store/types/user";
import { mapGetters } from "vuex";

export default {
  components: {
    EditUserInfo,
    UserRevenue,
    CreatePayment,
  },
  data() {
    return {
      tab: null,
    };
  },
  methods: {
    loadUserInfo() {
      const userId = Number.parseInt(this.$route.params.userId);
      this.$store.dispatch(userStoreTypes.getUserInfo, userId);
    },
  },
  mounted() {
    this.loadUserInfo();
  },
  updated() {
    this.loadUserInfo();
  },
  computed: {
    ...mapGetters(["getPayments"]),
    ...mapGetters(["getUserInfo"]),
  },
};
</script>