<template>
  <div>
    <v-app-bar absolute>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" dark v-bind="attrs" v-on="on">
            {{ getUsername }}
          </v-btn>
        </template>
        <v-list>
          <v-list-item>
            <v-btn small text color="accent" to="/">
              {{ $t("appBar.app") }}
            </v-btn>
          </v-list-item>
          <v-list-item>
            <v-btn small text color="accent" to="/change-password">
              {{ $t("appBar.changePassword") }}
            </v-btn>
          </v-list-item>
          <v-list-item>
            <v-btn small text color="accent" @click="logout">
              {{ $t("appBar.logout") }}
            </v-btn>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" absolute temporary>
      <v-list nav dense>
        <v-list-item-group v-model="group">
          <img class="logo" src="@/assets/images/logo.png" alt="Logo" />
          <v-btn text to="/admin/upload-report" color="primary">
            {{ $t("navigation.admin.uploadReports") }}
          </v-btn>
          <v-btn text to="/admin/reset-password" color="primary">
            {{ $t("navigation.admin.resetPassword") }}
          </v-btn>
          <v-btn text to="/admin/users" color="primary">
            {{ $t("navigation.admin.users") }}
          </v-btn>
          <v-btn text to="/admin/register-user" color="primary">
            {{ $t("navigation.admin.registerUser") }}
          </v-btn>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import * as accountStoreTypes from "@/store/types/account";

export default {
  data() {
    return {
      drawer: false,
      group: null,
    };
  },
  methods: {
    logout() {
      this.$store.dispatch(accountStoreTypes.logout);
    },
  },
  computed: {
    getUsername() {
      return this.$store.getters.getUsername;
    },
  },
  watch: {
    group() {
      this.drawer = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.logo {
  height: 60px;
}
</style>