<template>
  <ResetPassword class="page" />
</template>

<script>
import ResetPassword from "@/components/admin/ResetPassword";
export default {
  components: {
    ResetPassword,
  },
};
</script>