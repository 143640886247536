import DriverReferrals from '@/pages/Driver';
import DeliveryReferrals from '@/pages/Delivery';
import Login from '@/pages/Login';
import Signup from '@/pages/Signup';
import ChangePassword from '@/pages/ChangePassword';
import RecoverPassword from '@/pages/RecoverPassword';
import ResetPassword from '@/pages/ResetPassword';
import UploadReport from '@/pages/UploadReport';
import Admin from '@/pages/Admin';
import UserList from '@/pages/UserList';
import UserInfo from '@/pages/UserInfo';
import RegisterUser from '@/pages/RegisterUser';

export default [
  { path: '/', component: DriverReferrals },
  { path: '/driver', component: DriverReferrals },
  { path: '/delivery', component: DeliveryReferrals },
  { path: '/login', component: Login },
  { path: '/signup', component: Signup },
  { path: '/change-password', component: ChangePassword },
  { path: '/recover', component: RecoverPassword },
  {
    path: '/admin', redirect: 'admin/upload-report', component: Admin, children: [
      { path: 'upload-report', component: UploadReport },
      { path: 'reset-password', component: ResetPassword },
      { path: 'users', component: UserList },
      { path: 'user/:userId', component: UserInfo },
      { path: 'register-user', component: RegisterUser }
    ]
  }
]