import { api } from './api';

class LoginData {
  email: string;
  password: string;
}


export const login = (data: LoginData) => {
  return api().post('account/login', {
    login: data.email,
    password: data.password
  })
    .then(response => {
      let token = response.data;
      return token;
    })
    .catch(error => {
      throw error;
    });
}


class RegisterData {
  email: string;
  phone: string;
  password: string;
  name: string;
  surname: string;
  referrerPhone: string;
}

export const signup = (data: RegisterData) => {
  return api().post('account/register', {
    email: data.email,
    phone: data.phone,
    password: data.password,
    name: data.name,
    surname: data.surname,
    referrerPhone: data.referrerPhone
  })
    .then(response => {
      let status = response.data;
      return status;
    })
    .catch(error => {
      throw error;
    });
}

class ChangePasswordData {
  oldPassword: string;
  newPassword: string;
}

export const changePassword = (data: ChangePasswordData) => {
  return api().post('account/change-password', {
    oldPassword: data.oldPassword,
    newPassword: data.newPassword,
  })
    .then(response => {
      let status = response.data;
      return status;
    })
    .catch(error => {
      throw error;
    });
}

class ResetPasswordData {
  email: string
}

export const resetPassword = (data: ResetPasswordData) => {
  return api().post('account/reset-password', {
    email: data.email
  })
    .then(response => {
      let status = response.data;
      return status;
    })
    .catch(error => {
      throw error;
    });
}