<template>
  <UserInfo class="page" />
</template>

<script>
import UserInfo from "@/components/admin/UserInfo";
export default {
  components: {
    UserInfo,
  },
};
</script>