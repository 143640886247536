// mutations
export const setIsLoadingGetReferrals = 'setIsLoadingGetReferrals'
export const setIsGetReferralsError = 'setIsGetReferralsError'
export const setReferrals = 'setReferrals'

export const setIsLoadingGetTotalRevenue = 'setIsLoadingGetTotalRevenue'
export const setIsGetTotalRevenueError = 'setIsGetTotalRevenueError'

export const setIsLoadingGetReferralRevenue = 'setIsLoadingGetReferralRevenue'
export const setIsGetReferralRevenueError = 'setIsGetReferralRevenueError'

export const setTotalRevenue = 'setTotalRevenue'
export const setRevenues = 'setRevenues'

// actions
export const getMyReferrals = 'getMyReferrals'
export const getTotalRevenue = 'getTotalRevenue'
export const getReferralRevenue = 'getReferralRevenue'