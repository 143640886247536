<template>
  <v-form
    ref="form"
    @submit.prevent="login"
    lazy-validation
    class="loginForm d-flex align-center"
  >
    <v-container class="formContainer d-flex flex-column align-center">
      <img class="logo" src="@/assets/images/logo.png" alt="Logo" />

      <v-alert type="error" dismissible v-model="loginError">
        {{ $t("login.loginError") }}
      </v-alert>

      <v-text-field
        v-model="loginData.email"
        autofocus
        :label="$t('user.email')"
        required
        :rules="emailRules"
        type="email"
        class="textField"
      />

      <v-text-field
        v-model="loginData.password"
        :append-icon="passwordVisibility ? 'mdi-eye' : 'mdi-eye-off'"
        required
        :type="passwordVisibility ? 'text' : 'password'"
        :label="$t('user.password')"
        @click:append="passwordVisibility = !passwordVisibility"
        :rules="passwordRules"
        class="mt-8 textField"
      />

      <spinner :display="isLoading" />

      <v-btn class="mt-8" type="submit" color="accent" tile large>
        {{ $t("login.login") }}
      </v-btn>

      <v-row>
        <v-col sm="5">
          <v-btn to="/signup" x-small text color="accent">
            {{ $t("login.signup") }}
          </v-btn>
        </v-col>
        <v-col sm="7">
          <v-btn to="/recover" x-small text color="accent" disabled>
            {{ $t("login.recoverPassword") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import {
  isValidEmail,
  isValidPassword,
  passwordMinLength,
} from "@/validation/validation";
import * as accountStoreTypes from "@/store/types/account";
import Spinner from "@/components/shared/Spinner";

export default {
  components: {
    Spinner,
  },
  data() {
    return {
      emailRules: [
        (val) => !!val || this.$t("validation.requiredField"),
        (val) => isValidEmail(val) || this.$t("validation.wrongEmail"),
      ],
      passwordRules: [(val) => !!val || this.$t("validation.requiredField")],
      loginData: {
        email: null,
        password: null,
      },
      passwordVisibility: false,
    };
  },
  methods: {
    login() {
      if (this.$refs.form.validate()) {
        this.$store.dispatch(accountStoreTypes.login, this.loginData);
      }
    },
  },
  computed: {
    loginError() {
      return this.$store.getters.getLoginError;
    },
    isLoading() {
      return this.$store.getters.getIsLoginLoading;
    },
  },
};
</script>

<style lang="scss" scoped>
.loginForm {
  height: 100vh;
}

.formContainer {
  width: 300px;
}

.textField {
  width: 100%;
}

.logo {
  height: 100px;
}
</style>