<template>
  <v-container>
    <v-text-field
      v-if="this.valueLabel === true"
      v-model="dateRange"
      :label="$t('admin.pickDateRange')"
      :rules="requiredDate"
      readonly
    />
    <v-row>
      <v-date-picker
        :value="dates"
        :rules="requiredRules"
        @input="selectWeek"
        show-week
        first-day-of-week="1"
        multiple
      />
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    valueLabel: Boolean,
    callback: Function,
  },
  data() {
    return {
      requiredRules: [(val) => !!val || this.$t("validation.requiredField")],
      requiredDate: [
        (val) =>
          (!!val && val[0] != null && val[1] != null) ||
          this.$t("validation.requiredField"),
      ],
      dates: null,
      dateRange: null,
    };
  },
  methods: {
    selectWeek(date) {
      if (date.length != null && date.length > 0) {
        date = date[date.length - 1];
      }
      const weekValue = [];
      const selectedDate = new Date(date);
      const day = selectedDate.getDay() == 0 ? 6 : selectedDate.getDay() - 1;

      for (let dayNumber = 1; dayNumber <= day; dayNumber++) {
        const tempTimestamp = new Date(selectedDate.getTime()).setDate(
          selectedDate.getDate() - dayNumber
        );
        const tempDate = new Date(tempTimestamp);
        weekValue.unshift(this.formatDate(tempDate));
      }

      weekValue.push(date);

      for (let dayNumber = 1; dayNumber < 7 - day; dayNumber++) {
        const tempTimestamp = new Date(selectedDate.getTime()).setDate(
          selectedDate.getDate() + dayNumber
        );
        const tempDate = new Date(tempTimestamp);
        weekValue.push(this.formatDate(tempDate));
      }

      this.dates = weekValue;
      const dateFrom = weekValue[0];
      const dateTo = weekValue[6];
      this.dateRange = `[${dateFrom}; ${dateTo}]`;
      this.callback([dateFrom, dateTo]);
    },
    formatDate(date) {
      const dd = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
      const month = date.getMonth() + 1;
      const mm = month < 10 ? `0${month}` : month;
      const yyyy = date.getFullYear();
      return `${yyyy}-${mm}-${dd}`;
    },
    resetWeekSelector() {
      this.dates = null;
      this.dateRange = null;
    },
  },
};
</script>