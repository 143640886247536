import { login, signup, changePassword, resetPassword } from '@/api/account.ts';
import * as types from '../types/account'
import jwt_decode from "jwt-decode";

const state = {
  isLoggedIn: localStorage.token != null,
  token: localStorage.token,
  isLoginError: false,
  isLoginLoading: false,
  isSignupError: false,
  isSignupLoading: false,
  signupState: null,

  changePasswordState: null,
  isChangePasswordLoading: false,

  resettedPassword: null,
  isResetPasswordLoading: false,
  isResetPasswordError: false
};

const mutations = {
  setLoginState: (state, token) => {
    state.token = token;
    localStorage.token = token;
    state.isLoggedIn = true;
  },
  setLogoutState: (state) => {
    window.sessionStorage.clear();
    window.localStorage.clear();
    state.token = null;
    state.isLoggedIn = false;
  },
  setLoginError: (state, payload) => {
    state.isLoginError = payload;
  },
  setIsLoginLoading: (state, payload) => {
    state.isLoginLoading = payload;
  },
  setSignupError: (state, payload) => {
    state.isSignupError = payload;
  },
  setIsSignupLoading: (state, payload) => {
    state.isSignupLoading = payload;
  },
  setSignupState: (state, payload) => {
    state.signupState = payload;
  },

  setChangePasswordState: (state, payload) => {
    state.changePasswordState = payload;
  },
  setIsChangePasswordLoading: (state, payload) => {
    state.isChangePasswordLoading = payload;
  },

  setResettedPassword: (state, payload) => {
    state.resettedPassword = payload;
  },
  setIsResetPasswordLoading: (state, payload) => {
    state.isResetPasswordLoading = payload;
  },
  setIsResetPasswordError: (state, payload) => {
    state.setIsResetPasswordError = payload;
  }
}

const actions = {
  login: ({ commit }, payload) => {
    commit(types.setIsLoginLoading, true);
    login(payload)
      .then(token => {
        setTimeout(() => {
          commit(types.setLoginState, token);
        }, 500);
      })
      .catch(() => {
        setTimeout(() => {
          commit(types.setLoginError, true);
        }, 500);
        setTimeout(() => {
          commit(types.setLoginError, false);
        }, 3000);
      })
      .finally(() => {
        setTimeout(() => {
          commit(types.setIsLoginLoading, false);
        }, 500);
      });
  },
  logout: ({ commit }) => {
    commit(types.setLogoutState);
  },
  signup: ({ commit }, payload) => {
    commit(types.setIsSignupLoading, true);
    signup(payload)
      .then(status => {
        setTimeout(() => {
          commit(types.setSignupState, status);
        }, 500);
        setTimeout(() => {
          commit(types.setSignupState, null);
        }, 3000);
      })
      .catch(() => {
        setTimeout(() => {
          commit(types.setSignupError, true);
        }, 500);
        setTimeout(() => {
          commit(types.setSignupError, false);
        }, 3000);
      })
      .finally(() => {
        setTimeout(() => {
          commit(types.setIsSignupLoading, false);
        }, 500);
      });
  },
  changePassword: ({ commit }, payload) => {
    commit(types.setIsChangePasswordLoading, true);
    changePassword(payload)
      .then(isSuccess => {
        setTimeout(() => {
          if (isSuccess === true) {
            commit(types.setChangePasswordState, true);
          }
          else {
            commit(types.setChangePasswordState, false);
          }
        }, 500);
        setTimeout(() => {
          commit(types.setChangePasswordState, null);
        }, 3000);
      })
      .catch(() => {
        setTimeout(() => {
          commit(types.setIsResetPasswordError, 'error');
        }, 500);
        setTimeout(() => {
          commit(types.setIsResetPasswordError, null);
        }, 3000);
      })
      .finally(() => {
        setTimeout(() => {
          commit(types.setIsChangePasswordLoading, false);
        }, 500);
      });
  },
  resetPassword: ({ commit }, payload) => {
    commit(types.setIsResetPasswordLoading, true);
    resetPassword(payload)
      .then(resettedPassword => {
        setTimeout(() => {
          commit(types.setResettedPassword, resettedPassword);
        }, 500);
        setTimeout(() => {
          commit(types.setResettedPassword, null);
        }, 10000)
      })
      .catch(() => {
        setTimeout(() => {
          commit(types.setIsResetPasswordError, true);
        }, 500);
        setTimeout(() => {
          commit(types.setIsResetPasswordError, false);
        }, 3000);
      })
      .finally(() => {
        setTimeout(() => {
          commit(types.setIsResetPasswordLoading, false);
        }, 500);
      });
  }
}

const getters = {
  getLoginError: (state) => state.isLoginError,
  getToken: (state) => state.token,
  getIsLoggedIn: (state) => state.isLoggedIn,
  getIsLoginLoading: (state) => state.isLoginLoading,
  getSignupError: (state) => state.isSignupError,
  getSignupState: (state) => state.signupState,
  getIsSignupLoading: (state) => state.isSignupLoading,
  getChangePasswordState: (state) => state.changePasswordState,
  getIsChangePasswordLoading: (state) => state.isChangePasswordLoading,
  getUsername: (state) => {
    const decodedJwt = jwt_decode(state.token);
    return decodedJwt.username;
  },
  getAccountType: (state) => {
    const decodedJwt = jwt_decode(state.token);
    return decodedJwt.account_type;
  },
  getIsAdmin: (state) => {
    if (state.token != null) {
      const decodedJwt = jwt_decode(state.token);
      return decodedJwt.account_type === "Admin";
    }
    return false;
  },
  getIsResetPasswordLoading: (state) => state.isResetPasswordLoading,
  getResettedPassword: (state) => state.resettedPassword,
  getIsResetPasswordError: (state) => state.isResetPasswordError
}

export default {
  state,
  actions,
  mutations,
  getters
}