<template>
  <DeliveryReferrals class="page" />
</template>

<script>
import DeliveryReferrals from "@/components/referrals/Delivery";
export default {
  components: {
    DeliveryReferrals,
  },
};
</script>