<template>
  <div class="referralsContainer">
    <v-list class="referralsList">
      <v-list-item-group
        mandatory
        v-model="selectedReferral"
        color="primary"
        @change="changeReferral"
      >
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title v-text="$t('referrals.totalRevenue')" />
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-for="referral in myReferrals" :key="referral.id">
          <v-list-item-content>
            <v-list-item-title v-text="referral.username" />
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>

    <div class="allTimeTotalRevenue">
      <p class="subtitle-1">
        {{ $t("referrals.allTimeRevenue") }}
      </p>
      <p class="title accent--text">
        {{ `${totalRevenue} ${$t("shared.pln")}` }}
      </p>
    </div>
    <div class="totalRevenue">
      <p class="subtitle-1">
        {{ $t("referrals.selectedPeriodRevenue") }}
      </p>
      <p class="title accent--text">
        {{ `${selectedPeriodRevenues} ${$t("shared.pln")}` }}
      </p>
    </div>
    <div class="dateSelector">
      <v-radio-group
        class="datePickerType"
        v-model="datePickerType"
        mandatory
        row
      >
        <v-radio
          :label="this.datePicker.week.label"
          :value="this.datePicker.week.value"
        />
        <v-radio
          :label="this.datePicker.month.label"
          :value="this.datePicker.month.value"
        />
        <v-radio
          :label="this.datePicker.range.label"
          :value="this.datePicker.range.value"
        />
      </v-radio-group>
      <v-text-field
        v-model="datesLabel"
        :label="$t('referrals.pickPeriod')"
        readonly
        class="dates"
      />
      <WeekSelector
        :valueLabel="false"
        :callback="onWeekSelected"
        v-show="this.datePickerType === this.datePicker.week.value"
        class="datePicker"
      />
      <v-date-picker
        v-show="this.datePickerType === this.datePicker.month.value"
        class="datePicker"
        type="month"
        v-model="monthPicker"
        @change="onMonthSelected"
      />
      <v-date-picker
        v-show="this.datePickerType === this.datePicker.range.value"
        class="datePicker"
        range
        v-model="rangePicker"
        @change="onDateRangeSelected"
      />
    </div>
    <div class="chart">
      <chart :chartData="revenues" :options="this.options" />
    </div>
  </div>
</template>

<script>
import * as referralStoreTypes from "@/store/types/referral";
import Spinner from "@/components/shared/Spinner";
import WeekSelector from "@/components/shared/WeekSelector";
import Chart from "./Chart";

export default {
  props: {
    serviceType: null,
  },
  components: {
    Chart,
    WeekSelector,
  },
  data() {
    return {
      now: new Date(Date.now()),
      rangePicker: null,
      monthPicker: null,
      selectedReferral: 0,
      options: { responsive: true, maintainAspectRatio: false },
      datePicker: {
        week: {
          label: this.$t("shared.weekPicker"),
          value: "week",
        },
        month: {
          label: this.$t("shared.monthPicker"),
          value: "month",
        },
        range: {
          label: this.$t("shared.rangePicker"),
          value: "range",
        },
      },
      datePickerType: null,
      dates: [],
      datesLabel: "",
    };
  },
  methods: {
    getCurrentDate() {
      return new Date(Date.now());
    },
    getDateWithOffset(daysOffset) {
      const now = this.getCurrentDate();
      return new Date(now.setHours(now.getHours() - 24 * daysOffset));
    },
    onDateRangeSelected() {
      const firstDateValue = this.rangePicker[0];
      const firstDate = new Date(firstDateValue);
      const secondDate = new Date(this.rangePicker[1]);
      if (firstDate > secondDate) {
        this.dates[0] = this.rangePicker[1];
        this.dates[1] = firstDateValue;
      } else {
        this.dates[0] = this.rangePicker[0];
        this.dates[1] = this.rangePicker[1];
      }

      this.datesLabel = `[${this.dates[0]}; ${this.dates[1]}]`;

      this.executeProperRequest();
    },
    changeReferral() {
      this.executeProperRequest();
    },
    executeProperRequest() {
      if (this.selectedReferral == 0) {
        this.executeGetTotalRevenue();
      } else {
        this.executeGetReferralRevenue();
      }
    },
    executeGetTotalRevenue() {
      const data = this.getDate();
      data.serviceType = this.serviceType;
      this.$store.dispatch(referralStoreTypes.getTotalRevenue, data);
    },
    executeGetReferralRevenue() {
      const data = {
        referralId: this.getSelectedReferral().id,
        from: this.getDate().from,
        to: this.getDate().to,
        serviceType: this.serviceType,
      };
      this.$store.dispatch(referralStoreTypes.getReferralRevenue, data);
    },
    getSelectedReferral() {
      return this.myReferrals[this.selectedReferral - 1];
    },
    getDate() {
      if (
        this.dates != null &&
        this.dates[0] != null &&
        this.dates[1] != null
      ) {
        return {
          from: this.dates[0],
          to: this.dates[1],
        };
      }
      return {
        from: this.getDateWithOffset(9999),
        to: this.getCurrentDate(),
      };
    },
    onMonthSelected() {
      const from = `${this.monthPicker}-01`;
      const date = new Date(Date.parse(this.monthPicker));
      const lastDay = new Date(
        date.getFullYear(),
        date.getMonth() + 1,
        0
      ).getDate();
      const to = `${this.monthPicker}-${lastDay}`;
      this.dates[0] = from;
      this.dates[1] = to;
      this.datesLabel = `[${from}; ${to}]`;
      this.executeProperRequest();
    },
    onWeekSelected(weekDates) {
      const from = weekDates[0];
      const to = weekDates[1];
      this.dates[0] = from;
      this.dates[1] = to;
      this.datesLabel = `[${from}; ${to}]`;
      this.executeProperRequest();
    },
  },
  mounted() {
    this.$store.dispatch(referralStoreTypes.getMyReferrals);
    const data = this.getDate();
    data.serviceType = this.serviceType;
    this.$store.dispatch(referralStoreTypes.getTotalRevenue, data);
  },
  computed: {
    myReferrals() {
      return this.$store.getters.getReferrals;
    },
    myReferralsIsLoading() {
      return this.$store.getters.getIsLoadingGetReferrals;
    },
    myReferralsError() {
      return this.$store.getters.getIsGetReferralsError;
    },
    totalRevenueIsLoading() {
      return this.$store.getters.getIsLoadingGetTotalRevenue;
    },
    totalRevenueError() {
      return this.$store.getters.getIsGetTotalRevenueError;
    },
    referralRevenueIsLoading() {
      return this.$store.getters.getIsLoadingGetReferralRevenue;
    },
    referralRevenueError() {
      return this.$store.getters.getIsGetReferralRevenueError;
    },
    totalRevenue() {
      return this.$store.getters.getTotalRevenue;
    },
    revenues() {
      const revenues = this.$store.getters.getRevenues;
      const label = this.getSelectedReferral()
        ? this.getSelectedReferral().username
        : this.$t("referrals.totalRevenue");
      const chartData = {
        labels: Object.keys(revenues),
        datasets: [
          {
            label: label,
            backgroundColor: this.$vuetify.theme.themes.light.accent,
            data: Object.values(revenues),
          },
        ],
      };
      return chartData;
    },
    selectedPeriodRevenues() {
      const revenues = this.$store.getters.getRevenues;
      const values = Object.values(revenues);
      const reducer = (total, currentValue) => total + currentValue;
      return values.reduce(reducer, 0);
    },
  },
};
</script>

<style lang="scss" scoped>
.referralsContainer {
  padding: 20px;
  display: grid;
  grid-template:
    "referralsList allTimeTotalRevenue dateSelector"
    "referralsList totalRevenue dateSelector"
    "referralsList chart chart";
  column-gap: 20px;
  grid-template-rows: 1fr 1fr 2fr;
}

.referralsList {
  grid-area: referralsList;
  overflow-y: auto;
  width: 300px;
  height: calc(100vh - 64px - 40px);
}

.allTimeTotalRevenue {
  grid-area: allTimeTotalRevenue;
}

.totalRevenue {
  grid-area: totalRevenue;
}

.dateSelector {
  grid-area: dateSelector;
  display: grid;
  grid-template:
    "datePickerType datePicker"
    "dates datePicker";
  justify-content: flex-end;
  column-gap: 20px;
  grid-template-rows: 1fr 3fr;

  .datePickerType {
    grid-area: datePickerType;
  }

  .dates {
    grid-area: dates;
  }

  .datePicker {
    grid-area: datePicker;
  }
}

.chart {
  grid-area: chart;
  align-self: flex-end;
}

@media screen and (max-width: $lg_max) {
  .referralsContainer {
    grid-template-rows: 1.5fr 1fr;
  }
  .dateSelector {
    grid-template:
      "datePickerType"
      "dates"
      "datePicker";
    grid-template-rows: 1fr 1fr 5fr;
  }
  .chart {
    align-self: flex-start;
  }
}

@media screen and (max-width: $md_min) {
  .referralsContainer {
    grid-template:
      "referralsList"
      "allTimeTotalRevenue"
      "totalRevenue"
      "dateSelector"
      "chart";
    grid-template-rows: 4fr 1fr 1fr 5fr 3fr;
    row-gap: 20px;
    justify-items: center;
  }

  .referralsList {
    height: auto;
  }

  .dateSelector {
    justify-content: flex-start;
  }

  .totalRevenue,
  .allTimeTotalRevenue {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (max-width: $xs_max) {
  .chart {
    width: 300px;
  }
}
</style>